import React from "react"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Carousel from 'react-bootstrap/Carousel'

function getPicturePath(imgName) {
    const images = require.context('../images/', true);
    return images('./' + imgName)
}

const Jardin = () => (
    <Container fluid>
        <Row>
            <Col sm className="p-0 d-flex align-items-center">
                <Carousel data-interval="8000" className='extend'>
                    <Carousel.Item>
                        <div className="ratio ratio1"></div>                
                    </Carousel.Item>
                    <Carousel.Item className="m-0">
                        <div className="ratio ratio2 m-0"></div>
                    </Carousel.Item>
                </Carousel>
            </Col>

            <Col sm className="p-0">
                <h1 className="h1Bandeau" >Jardin</h1>
                <Container fluid>
                    <Row className="m-3 justified d-flex align-items-center">
                        <Col xs={2}>
                            <Image src={getPicturePath("tree_maroon.png")} width="100%" className="mb-3 mt-3 unim" />
                        </Col>
                        <Col xs={10}>
                            N’hésitez pas à vous offrir un moment de détente dans un écrin de verdure.
                            Hiva Hoa Massage se situe dans un jardin fleuri et verdoyant tout prêt du bois de Thouars.
                            Si le temps le permet vous pourrez y recevoir un massage. Agrémenté d’un bassin, le jardin respire la quiétude.
                        </Col>
                    </Row>
                    {/* <br></br> */}
                    <Row className="m-3 justified d-flex align-items-center">
                        <Col xs={2}>
                            <Image src={getPicturePath("flower_maroon.png")} width="100%" className="mb-3 mt-3" />
                        </Col>
                        <Col xs={10}>
                            Les bienfaits d’un massage reçu dans la nature sont bien plus bénéfiques et ressourçants qu’un massage reçu en intérieur.
                            Au contact de la nature, bercé par le chant des oiseaux,
                            sentir la douceur du vent durant le massage permet un relâchement complet du corps et
                            procure une sensation de bien-être beaucoup plus profonde et plus intense.
                        </Col>
                    </Row>

                </Container>
            </Col>
        </Row>
    </Container>

)

export default Jardin
