import React from "react"
import Bandeau from "../components/bandeau"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
// import Carousel from 'react-bootstrap/Carousel'

function getPicturePath(imgName) {
    const images = require.context('../images/', true);
    return images('./' + imgName)
}

const BienEtre = () => (

    <Bandeau title="Bien-être" dark>
        <Container fluid>
            <br></br>
            <Row>
                <Col sm className="text-center">
                    <Image src={getPicturePath("feather2_maroon.png")} width="20%" className="mb-3 mt-3" />
                    <br></br>
                    <p>
                        Ces différents types de massages sont effectués par un toucher
                        particulièrement lent et très doux permettant au corps de
                        se relâcher avec plus de facilité.
                    </p>
                </Col>
                <Col sm className="text-center pl-4 pr-4">
                    <Image src={getPicturePath("massage_maroon.png")} width="20%" className="mb-3 mt-3" />
                    <br></br>
                    <p>
                        Plusieurs points d'acupressions sont sollicités au cours de ces massages ce qui permet
                        d'activer les méridiens et de favoriser ainsi la circulation des énergies.
                    </p>
                </Col>
                <Col sm className="text-center">

                    <Image src={getPicturePath("zen_maroon.png")} width="20%" className="mb-3 mt-3" />
                    <br></br>
                    <p>
                        En harmonisant les énergies, tensions et blocages se libèrent,
                        le receveur ressent alors un vrai bien être.
                    </p>
                </Col>
            </Row>
            <br></br>
            <br></br>
        </Container>
    </Bandeau>

)

export default BienEtre
