import React from "react"
import Bandeau from "../components/bandeau"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

function getPicturePath(imgName) {
    const images = require.context('../images/', true);
    return images('./' + imgName)
}


const PetitsPlus = () => (

    <Bandeau title="Osons aller plus loin" dark>

        <Container >
            <br></br>
            <Row>
                <h3 className="darkText">La numéro-biologie et l’énergie du prénom</h3>
            </Row>

            <Row className="align-items-center ">
                <Col sm lg={9} className="pr-3 justified" >
                    Il s’agit d’une lecture de soi surprenante au travers de l’énergie des chiffres et des lettres.
                    Mieux appréhender son histoire familiale pour avoir une meilleure connaissance de soi.
                    Comprendre qui nous sommes pour améliorer notre communication avec l’autre,
                    mieux gérer notre stress et nos émotions pour avancer différemment dans la vie.
                    La lecture d’un thème nous permet d’avoir un autre regard sur nos vies. 
                    <br></br>
                    <br></br>
                    <i>« Oser une lecture unique car nous sommes uniques »</i>
                </Col>
                <Col sm lg={3} className="p-3">
                    <Image src={getPicturePath('numerobiologie.png')} rounded className="p-3 m-0" />
                </Col>
            </Row>

            <br></br>
            <br></br>

            
            <Row className="d-flex justify-content-end">
                <h3 className="darkText">L’astrologie chinoise</h3>
            </Row>

            <Row className="align-items-center ">
                <Col sm lg={3} className="p-3">
                    <Image src={getPicturePath('chinois.png')} rounded className="p-3 m-0" />
                </Col>
                <Col sm lg={9} className="pr-3 justified" >
                    Peut être un support supplémentaire pour accentuer les bienfaits d’un massage.
                    En effet, à partir de la date de naissance,
                    il est possible de déterminer les éléments qui nous apportent un soutien,
                    une force mais de découvrir également les éléments qui peuvent nous fragiliser.
                    Ces éléments sont la terre, l’eau, le métal, le bois et le feu.
                    En ayant connaissance de ces éléments,
                    Hiva Hoa massage orientera les choix des draps de table et serviettes de toilettes
                    aux couleurs les mieux adaptées à la personne afin que ce doux massage puisse être le plus apaisant,
                    le plus relaxant et le plus bénéfique possible. 
                    <br></br>
                    <br></br>
                    Possibilité d’ouvrir votre horoscope chinois afin de connaître les éléments
                    qui vous sont favorables (Terre, Métal, Eau, Bois, Feu).
                    En connaissant les éléments dont vous avez besoin
                    comme support et ceux que vous devez éviter,
                    nous pouvons ainsi accentuer les bienfaits du massage.
                </Col>
            </Row>

            <br></br>
            <br></br>

            <Row>
                <h3 className="darkText">Connaissances approfondies en Feng Shui</h3>
            </Row>

            <Row className="align-items-center">


                <Col sm lg={9} className="justified pl-3">
                    Pour moi, il y a un lien profond entre la personne, le lieu de vie et la nature.
                    Très proche de l’humain, de la nature et des animaux,
                    j’ai essayé de créer un équilibre et une harmonie entre mon lieu de travail
                    et mon jardin afin de vous conduire vers un moment de détente très relaxant.
                    Ma formation en Feng Shui Traditionnel m’a permis d’accéder à une écoute du lieu de vie,
                    de la personne, et de poser un nouveau regard sur l’environnement en général. 

                </Col>
                <Col sm lg={3} className="p-3">
                    <Image src={getPicturePath('gong.jpg')} rounded className="p-3 m-0" />
                </Col>
            </Row>


            
            <br></br>
            <br></br>

            <Row className="d-flex justify-content-end">
                <h3 className="darkText">Soin en Ehpad</h3>
            </Row>

            <Row className="align-items-center">


                <Col sm lg={3} className="p-3">
                    <Image src={getPicturePath('ehpad.jpg')} rounded className="p-3 m-0" />
                </Col>
                <Col sm lg={9} className="justified pl-3">

                    J’interviens en Ehpad auprès des personnes âgées et des personnes atteintes de la pathologie d’Alzheimer en unité protégée.
                    Ces personnes sont particulièrement réceptives au massage car le toucher demeure très important pour elles et reste un moyen de communication privilégié entre le donneur et le receveur. 
                    <br></br>
                    <br></br>
                    La bientraitance, la bienveillance, et l’humanitude sont des mots clefs et des valeurs qui me sont chères.
                    Le toucher est magnétique, il permet de transmettre bien-être et douceur au patient en souffrance.
                    Le toucher stimule le corps et permet aussi d’apaiser les tensions et le stress qui caractérisent cette pathologie. 
                </Col>
            </Row>


            
            <br></br>

            {/* <Row>
                <h3 className="darkText">Horoscope chinoise</h3>
            </Row>

            <Row className="align-items-center ">
                <Col sm lg={9} className="pr-3 justified" >
                    Possibilité d’ouvrir votre horoscope chinois afin de connaître les éléments
                    qui vous sont favorables (Terre, Métal, Eau, Bois, Feu).
                    En connaissant les éléments dont vous avez besoin
                    comme support et ceux que vous devez éviter,
                    nous pouvons ainsi accentuer les bienfaits du massage.  
                </Col>
                <Col sm lg={3} >
                    <Image src={getPicturePath('chinois.png')} rounded className="m-3" />
                </Col>
            </Row> */}

            <br></br>

        </Container>

    </Bandeau>

)
export default PetitsPlus
