import React from "react"
import Card from 'react-bootstrap/Card'
import { Link } from "gatsby"

function getPicturePath(imgName) {
    const images = require.context('../images/', true);
    return images('./' + imgName)
}

function getUrlId(id){
    return "/prestations#" + id
}

const CardPrestation = ({title, price, time, children, image, urlid}) => (
        <Card className="card-presta m-2 p-0 mb-5">
            <Card.Img src={getPicturePath(image)} className="card-img-top" alt="..."/>
            <Card.Body >
            <Card.Title className="d-flex justify-content-center darkText"> {title}</Card.Title>
                
                <Card.Text className="d-flex justify-content-center">
                    {children}
                </Card.Text>
                
            </Card.Body>
            <Link className="d-flex justify-content-center darkLink" to={getUrlId(urlid)}>Découvrir</Link>
            <br></br>
            <Card.Footer className="d-flex justify-content-center text-muted">{time}</Card.Footer>
        </Card>
)

export default CardPrestation
