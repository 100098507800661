import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Accueil from "../containers/accueil"
import Prestation from "../containers/prestation"
import BienEtre from "../containers/bien-etre"
import PetitsPlus from "../containers/les-petits-plus"
import Jardin from "../containers/jardin"
import Apropos from "../containers/apropos"
import Partenaire from "../containers/partenaire"

const IndexPage = () => (

  <Layout>
    <SEO title="Accueil" />
    <Accueil />
    <Prestation />
    <BienEtre />
    <Jardin />
    <PetitsPlus />
    <Apropos />
    {/* <Partenaire /> */}
  </Layout>

)

export default IndexPage
