import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Bandeau from "../components/bandeau"
import CardPrestation from '../components/card-prestation'

import { CardDeck } from "react-bootstrap"

export default function Prestation() {

  const data = useStaticQuery(
    graphql`
        query {
            allMarkdownRemark(sort: {fields: frontmatter___position}) {
              totalCount
              edges {
                node {
                  id
                  tableOfContents
                  frontmatter {
                    subtitle
                    title
                    prix
                    dur_e
                    image
                    urlid
                    range
                  }
                  excerpt
                }
              }
            }
          }
        `
  )

  return (
    <Bandeau title="Prestations">
      <CardDeck>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <CardPrestation
            title={node.frontmatter.title}
            price={node.frontmatter.prix}
            time={node.frontmatter.range}
            image={node.frontmatter.image}
            urlid={node.frontmatter.urlid}
            key={node.id}>
            {node.excerpt}
          </CardPrestation>
        ))}
      </CardDeck>
    </Bandeau>
  )
}





