import React from "react"
import Bandeau from "../components/bandeau"
import Image from 'react-bootstrap/Image'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function getPicturePath(imgName) {
    const images = require.context('../images/', true);
    return images('./' + imgName)
  }

const Apropos = () => (

    <Bandeau title="Votre masseuse">
        <Container>
            <Row className="d-flex justify-content-center">
                <Image src={getPicturePath('nad.jpg')} roundedCircle width="200px" className="m-0" />
            </Row>
            <br></br>
            <br></br>
            <Row className="d-flex">
                <p className='justified'>                  
                    Bien être ou mieux être, voici des mots qui résonnent en moi depuis de nombreuses années.
                    A l’écoute des gens et de la nature,
                    je me suis tout naturellement tournée vers une formation traditionnelle
                    au massage issue de techniques ancestrales qui me permet
                    d’être à l’écoute de la personne et du corps.        
                </p>
                <p className='justified'>
                    Animatrice dans un atelier artistique,
                    ma sensibilité s’exprimait par le biais de ma créativité mais cela ne me suffisait plus.
                    J’ai un jour éprouvé le besoin de relier créativité et humain : le massage s’impose à moi comme par magie.
                </p>
                <p className='justified'>
                    Il me semble aujourd’hui nécessaire de réapprendre à sentir son corps
                    et ses émotions pour un mieux être dans notre quotidien.
                    Le massage est pour moi un vrai cadeau de la vie.
                    C’est avec joie et cœur que je vous invite à venir vous découvrir
                    dans un moment de profonde relaxation et de grande détente.
                </p>
            </Row>
            <Row className="text-right">
                <Col md={6} className="p-0"></Col>
                <Col md={6} className="p-0">
                <span>Au plaisir de vous recevoir,</span> 
                <br></br>
                <span>Nadine Comblat</span>
                </Col>
            </Row>
        </Container>
    </Bandeau>

)
export default Apropos
