
import React from "react"
import Container from 'react-bootstrap/Container'
// import Typist from 'react-typist';

const Accueil = () => (
    <Container fluid className="homepage-bgimage d-flex">
        {/* <Typist>
            <br></br>
            <h1 className="welcome mt-5"> B I E N V E N U E </h1>
            <h2 className=""> Hiva Hoa Massage </h2>
            <br></br>
            <h3 className=""> Bien-être </h3>
            <Typist.Backspace count={8} delay={200} avgTypingDelay={500} />
            <span> Phrase </span>
        </Typist> */}
    </Container>

)

export default Accueil